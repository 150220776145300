/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('owl.carousel');

$(document).ready(function () {
    // Now to start autoTyping just call the autoType function with the 
    // class of outer div
    // The second paramter is the speed between each letter is typed.   
    autoType(".type-js", 200);
});

// $(document).ready(function () {
//     $('.popup-with-zoom-anim').magnificPopup({
//         type: 'inline',

//         fixedContentPos: false,
//         fixedBgPos: true,

//         overflowY: 'auto',

//         closeBtnInside: true,
//         preloader: false,

//         midClick: true,
//         removalDelay: 300,
//         mainClass: 'my-mfp-zoom-in'
//     });

//     $('.popup-with-move-anim').magnificPopup({
//         type: 'inline',

//         fixedContentPos: false,
//         fixedBgPos: true,

//         overflowY: 'auto',

//         closeBtnInside: true,
//         preloader: false,

//         midClick: true,
//         removalDelay: 300,
//         mainClass: 'my-mfp-slide-bottom'
//     });
// });

$(window).on("scroll", function () {
    var scroll = $(window).scrollTop();

    if (scroll >= 80) {
        $("#site-header").addClass("nav-fixed");
    } else {
        $("#site-header").removeClass("nav-fixed");
    }
});

//Main navigation Active Class Add Remove
$(".navbar-toggler").on("click", function () {
    $("header").toggleClass("active");
});
$(document).on("ready", function () {
    if ($(window).width() > 991) {
        $("header").removeClass("active");
    }
    $(window).on("resize", function () {
        if ($(window).width() > 991) {
            $("header").removeClass("active");
        }
    });
});

$(function () {
    $('.navbar-toggler').click(function () {
        $('body').toggleClass('noscroll');
    })
});



$('#service-carousel').owlCarousel({
    loop:true,
    margin:10,
    nav:false,
    items:1,
    autoplay:true,
    autoplayTimeout:6000,
    autoplayHoverPause:true
    // responsive:{
    //     0:{
    //         items:1
    //     },
    //     600:{
    //         items:2
    //     },
    //     1000:{
    //         items:2
    //     }
    // }
})

$('.slide-carousel').owlCarousel({
    loop:true,
    margin:10,
    nav:true,
    items:1,
    autoplay:true,
    autoplayTimeout:6000,
    autoplayHoverPause:true,
    dots: false
})

$('#testimonials-carousel').owlCarousel({
    loop:true,
    margin:10,
    nav:false,
    autoplay:true,
    autoplayTimeout:6000,
    autoplayHoverPause:true,
    responsive:{
        0:{
            items:1
        },
        600:{
            items:2
        },
        1000:{
            items:3
        }
    }
})